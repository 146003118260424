import { getStepsByType } from 'workflow/reducer'
import useWorkflow from './useWorkflow'
import { actionTypes } from 'workflow/components/Step/types/Action/actionTypes'
import useSWR from 'swr'
import httpClient from 'shared/utils/httpClient'

const INTEGRATION_AVAILABILITY_API = `${process.env.REACT_APP_API_HOST}/api/dashboard/google-integration/spreadsheet-mappings/statuses`

export const useIntegrationAvailability = () => {
  const mappings = useWorkflow(getStepsByType(actionTypes.addRowToGoogleSheet))

  const { data, isLoading } = useSWR(() => {
    if (!mappings || mappings.length === 0) return null

    const params = new URLSearchParams()
    mappings.forEach(({ mapping }) => params.append('ids[]', mapping?.id))

    return `${INTEGRATION_AVAILABILITY_API}?${params}`
  }, httpClient.get)

  return { data, isLoading }
}
