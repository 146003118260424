import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import CircleExclamationIcon from 'shared/icons/circle-exclamation'
import Group from '../../shared/Group'
import Tippy from '@tippyjs/react/headless'
import styled from 'styled-components'
import { TopLayerContext } from 'workflow/components/TopLayer'
import { useMappingAvailability } from 'workflow/hooks/use-mapping-availability'

const PopoverUi = styled.div`
  max-width: 300px;
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  border-radius: 0.5rem;
`

export default function GoogleActionWarning({ x, y, mappingId }) {
  const { t } = useTranslation()
  const { renderAtTopLayer } = useContext(TopLayerContext)

  const { available, isLoading } = useMappingAvailability(mappingId)

  if (isLoading || available || !mappingId) {
    return null
  }

  return renderAtTopLayer(
    <Tippy
      render={attrs => (
        <PopoverUi {...attrs}>
          <span
            dangerouslySetInnerHTML={{
              __html: t(
                'workflow.steps.action.errors.google_integration_missing',
                {
                  link: `${process.env.REACT_APP_API_HOST}/dashboard/profile/google-integration`,
                },
              ),
            }}
          ></span>
        </PopoverUi>
      )}
      hideOnClick
      trigger="mouseenter click"
    >
      <Group width="20px" height="20px" transform={`translate(${x}, ${y})`}>
        <CircleExclamationIcon
          style={{ color: 'red' }}
          width="20px"
          height="20px"
        />
      </Group>
    </Tippy>,
  )
}
