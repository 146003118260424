import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import Group from 'shared/components/Group'
import Drives from 'workflow/components/Drives'
import GDriveFileSelect from 'workflow/components/GDriveFileSelect'
import WorksheetColumns from 'workflow/components/WorksheetColumns'
import Worksheets from 'workflow/components/Worksheets'
import useMapping from 'workflow/hooks/useMapping'
import { useUser } from 'workflow/hooks/useUser'
import { isDisabledEditing, modifyMapping } from './utils'
import { useTranslation } from 'react-i18next'

const ActionTypeAddRowToGoogleSheet = forwardRef(
  function ActionTypeAddRowToGoogleSheet({ change, data }, ref) {
    const { t } = useTranslation()

    const mappingsRef = useRef({})
    const { user } = useUser()

    const { disabled, reason } = isDisabledEditing(user)

    const { mapping } = useMapping({
      mappingId: data?.id,
    })

    const handleChange = property => ({ value, label }) =>
      change(prev => ({
        ...prev,
        [property + 'Id']: value,
        [property + 'Name']: label,
      }))

    const handleMappingChange = (columnId, contents) => {
      change(prev => {
        const modified = modifyMapping(prev?.mapping, columnId, contents)

        return {
          ...prev,
          mapping: modified,
        }
      })
    }

    const collectValues = () => {
      if (!mappingsRef.current) {
        return data
      }

      const modifiedMapping = Object.values(mappingsRef.current).reduce(
        (acc, mapping) => {
          const { column: columnId, contents } = mapping.getValue()
          return modifyMapping(acc, columnId, contents)
        },
        data.mapping?.slice() ?? [],
      )

      return { ...data, mapping: modifiedMapping }
    }

    useImperativeHandle(ref, () => ({
      collectValues,
    }))

    useEffect(() => {
      if (mapping) {
        change(prev => ({
          ...prev,
          ...mapping,
        }))
      }
    }, [mapping])

    const drive = data?.integrationId && {
      id: data.integrationId,
      name: data.integrationName,
    }
    const spreadsheet = data?.spreadsheetId && {
      id: data.spreadsheetId,
      name: data.spreadsheetName,
    }
    const sheet = data?.sheetId && {
      id: data.sheetId,
      name: data.sheetName,
    }

    return (
      <>
        {disabled && (
          <Group>
            <p>{t(reason)}</p>
          </Group>
        )}
        <Group>
          <Drives
            disabled={disabled}
            selected={drive}
            onChange={handleChange('integration')}
          />
        </Group>
        {drive?.id && (
          <Group>
            <GDriveFileSelect
              disabled={disabled}
              driveId={drive.id}
              value={spreadsheet}
              onChange={handleChange('spreadsheet')}
            />
          </Group>
        )}
        {drive?.id && spreadsheet?.id && (
          <>
            <Group>
              <Worksheets
                disabled={disabled}
                spreadsheetId={spreadsheet?.id}
                driveId={drive?.id}
                value={sheet}
                onChange={handleChange('sheet')}
                selected={sheet}
              />
            </Group>
            <WorksheetColumns
              disabled={disabled}
              ref={mappingsRef}
              driveId={drive?.id}
              spreadsheetId={spreadsheet?.id}
              sheetId={sheet?.id}
              mapping={data?.mapping ?? []}
              onChange={handleMappingChange}
            />
          </>
        )}
      </>
    )
  },
)
export default ActionTypeAddRowToGoogleSheet
