import React, { useRef, useState } from 'react'
import { produce } from 'immer'
import { useTranslation } from 'react-i18next'
import ReactSelectEnhanced from 'shared/components/ReactSelectEnhaced'
import ActionType from 'workflow/components/Step/types/Action/ActionType'
import Button from 'shared/components/Button'
import Group from 'shared/components/Group'
import FormErrors from 'shared/components/FormErrors'
import { courseAccessTypes } from './ActionType/ActionTypeEnrollInCourse'
import { enrollmentAccessTypes } from './ActionType/ActionTypeEnrollInCourseBundle'
import {
  actionTypeToDataDict,
  actionTypes,
  actionTypesDescriptions,
  actionTypesLabels,
} from './actionTypes'
import { getActionIcon } from 'workflow/components/Step/types/Action/utils/get-action-icon'
import { useUser } from 'workflow/hooks/useUser'
import {
  filterActionTypeByAccess,
  disableActionTypeByAccess,
} from './action-type-restrictions'

const getRestrictionFilteredActionTypeOptions = user =>
  Object.values(actionTypes)
    .filter(filterActionTypeByAccess(user?.roles))
    .map(stepValue => ({
      value: stepValue,
      label: actionTypesLabels[stepValue],
    }))
    .map(disableActionTypeByAccess(user))

const ActionForm = ({
  stepData,
  handleSubmit,
  submitText,
  isLoading,
  errors,
  disabled,
}) => {
  const [t] = useTranslation()
  const [data, setData] = useState(stepData)
  const { user } = useUser()
  const formRef = useRef()

  const handleChangeType = option => {
    const nextData = produce(data, draftData => {
      const property = actionTypeToDataDict[draftData.type]

      if (property) {
        draftData[property] = {}

        if (option.value === actionTypes.enrollInCourse) {
          draftData[property].courseAccessType = courseAccessTypes.fullAccess
        } else if (option.value === actionTypes.enrollInCourseBundle) {
          draftData[property].enrollmentAccessType =
            enrollmentAccessTypes.fullAccess
        }
      }

      draftData.type = option.value
    })

    setData(nextData)
  }

  const handleChange = payload => {
    const property = actionTypeToDataDict[data.type]
    setData(prev => ({
      ...prev,
      [property]:
        typeof payload === 'function' ? payload(prev[property]) : payload,
    }))
  }

  const handleSave = () => {
    if (formRef.current && formRef.current.collectValues) {
      const property = actionTypeToDataDict[data.type]
      const formData = formRef.current.collectValues()
      handleChange(formData)
      handleSubmit({ ...data, [property]: formData })
      return
    }

    handleSubmit(data)
  }

  const handleSaveDangerous = (payload, closeOnSubmit) => {
    const property = actionTypeToDataDict[data.type]
    const submittedData = payload
      ? {
          ...data,
          [property]: payload,
        }
      : data
    return handleSubmit(submittedData, closeOnSubmit)
  }

  const submitDisabled = disabled || isLoading || !data.type

  const options = getRestrictionFilteredActionTypeOptions(user)

  return (
    <>
      <Group data-testid="workflow-step-action-type">
        <ReactSelectEnhanced
          placeholder={t('workflow.steps.types.action.types.placeholder')}
          selected={data.type}
          options={options}
          onChange={handleChangeType}
          getValueIcon={getActionIcon}
          valueDescriptions={actionTypesDescriptions}
        />
      </Group>
      {data.type && (
        <ActionType
          ref={formRef}
          change={handleChange}
          type={data.type}
          data={data}
          save={handleSaveDangerous}
          isLoading={isLoading}
        />
      )}
      {errors.length > 0 && (
        <Group>
          <FormErrors errors={errors} />
        </Group>
      )}

      {(data && data.type) !== actionTypes.sendEmail &&
        (data && data.type) !== actionTypes.sendEmailToSpecificAddress && (
          <Group>
            <Button onClick={handleSave} disabled={submitDisabled} success>
              {submitText}
            </Button>
          </Group>
        )}
    </>
  )
}

ActionForm.defaultProps = {
  errors: [],
}

export default ActionForm
