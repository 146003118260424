import { IntegrationAvailabilityEnum } from 'workflow/consts/integration-availability'
import { useIntegrationAvailability } from './use-integration-availability'

export const useMappingAvailability = mappingId => {
  const { data: integrationStatus, isLoading } = useIntegrationAvailability()

  if (!mappingId) return {}

  const mappingIntegrationStatus = integrationStatus?.[mappingId]

  return {
    available: mappingIntegrationStatus === IntegrationAvailabilityEnum.Ok,
    isLoading,
  }
}
