import React, { forwardRef } from 'react'
import ActionTypeAddRemoveTag from 'workflow/components/Step/types/Action/ActionType/ActionTypeAddRemoveTag'
import ActionTypeEnrollInCourse from 'workflow/components/Step/types/Action/ActionType/ActionTypeEnrollInCourse'
import ActionTypeSendEmail from 'workflow/components/Step/types/Action/ActionType/ActionTypeSendEmail'
import ActionTypeSendEmailWithRecipient from 'workflow/components/Step/types/Action/ActionType/ActionTypeSendEmailWithRecipient'
import ActionTypeSendWebhook from 'workflow/components/Step/types/Action/ActionType/ActionTypeSendWebhook'
import ActionTypeSubscribeToCampaign from 'workflow/components/Step/types/Action/ActionType/ActionTypeSubscribeToCampaign'
import ActionTypeRevokeAccessToCourse from './ActionTypeRevokeAccessToCourse'
import {
  actionTypeToDataDict,
  actionTypes,
} from 'workflow/components/Step/types/Action/actionTypes'
import ActionTypeRevokeAccessToCommunity from './ActionTypeRevokeAccessToCommunity'
import ActionTypeRevokeAccessToCourseBundle from './ActionTypeRevokeAccessToCourseBundle'
import ActionTypeEnrollInCourseBundle from './ActionTypeEnrollInCourseBundle'
import ActionTypeAddRowToGoogleSheet from './ActionTypeAddRowToGoogleSheet'

const selectComponent = type => {
  switch (type) {
    case actionTypes.addTag:
    case actionTypes.removeTag:
      return ActionTypeAddRemoveTag
    case actionTypes.subscribeToCampaign:
    case actionTypes.unsubscribeFromCampaign:
      return ActionTypeSubscribeToCampaign
    case actionTypes.enrollInCourse:
      return ActionTypeEnrollInCourse
    case actionTypes.revokeAccessToCourse:
      return ActionTypeRevokeAccessToCourse
    case actionTypes.sendWebhook:
      return ActionTypeSendWebhook
    case actionTypes.sendEmail:
      return ActionTypeSendEmail
    case actionTypes.sendEmailToSpecificAddress:
      return ActionTypeSendEmailWithRecipient
    case actionTypes.revokeAccessToCommunity:
      return ActionTypeRevokeAccessToCommunity
    case actionTypes.enrollInCourseBundle:
      return ActionTypeEnrollInCourseBundle
    case actionTypes.revokeAccessToCourseBundle:
      return ActionTypeRevokeAccessToCourseBundle
    case actionTypes.addRowToGoogleSheet:
      return ActionTypeAddRowToGoogleSheet
    default:
      throw new Error(`Unknown action type ${type}`)
  }
}

const ActionType = forwardRef(function ActionType(
  { type, change, data, save, isLoading },
  ref,
) {
  const Component = selectComponent(type)

  const property = actionTypeToDataDict[type]

  return (
    <Component
      change={change}
      data={data[property]}
      save={save}
      isLoading={isLoading}
      ref={ref}
    />
  )
})

export default ActionType
